import { GoogleGenerativeAI } from "@google/generative-ai";

export const getRecommendations = async (preferences) => {
  const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
  
  if (!apiKey) {
    throw new Error('Gemini API key is not configured. Please check your environment variables.');
  }

  try {
    const genAI = new GoogleGenerativeAI(apiKey);
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    
    const prompt = `Act as a gift recommendation expert. I need 5 specific gift suggestions based on these details:
    - Recipient: ${preferences.recipientType === 'Custom' ? preferences.recipient : preferences.recipientType}
    - Age: ${preferences.age}
    - Interests: ${preferences.interests}
    - Maximum Budget: $${preferences.budget}
    
    IMPORTANT: Each suggestion MUST be priced BELOW $${preferences.budget}. Do not suggest any items that exceed this budget.
    
    For each gift, with a minimum of 4 gift ideas provide the following in this exact format:
    1. Gift Name: [name]
    Price Range: [must be below $${preferences.budget}]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})`;

    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    if (!text) {
      throw new Error('Empty response from AI');
    }

    return text;
  } catch (error) {
    console.error('Error in getRecommendations:', error);
    if (error.message.includes('API_KEY_INVALID')) {
      throw new Error('Invalid API key. Please check your environment configuration.');
    }
    throw error;
  }
}; 